import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import smw1 from "../../images/news/article-4/TED7.jpg"
import smw2 from "../../images/news/article-4/TED4.jpg"
import smw3 from "../../images/news/article-4/TED3.jpg"

const Article4Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>Seminars, Meetings, Workshops</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.imageRow }>
                <img src={smw1} alt=""/>
                <img src={smw2} alt=""/>
                <img src={smw3} alt=""/>
            </div>
            <p>With over two decades of experience, countless seminars, meetings, workshops and special presentations have already been conducted for various (repeat) clients from different industries.</p>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article4Page